<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { useOffersStore } from '~/stores/account/offers.store';
import ListOffers from './list.vue'
import { onMounted, ref } from 'vue';
import hasRole from '~/components/account/has-role.vue';
import { useUserStore } from '~/stores/user.store';

const route = useRoute();
const router = useRouter()
const isLoading = ref(true)
const projectsStore = useOffersStore();
const userStore = useUserStore();
const localePath = useLocalePath();

onMounted(async () => {
	const user = userStore.userGetter()
	if (!user) {
		router.push({ path: localePath({ name: 'account.login' }) });
	}
	await projectsStore.fetchOffers();
	isLoading.value = false
})

</script>
<template>
	<NuxtLayout name="mobile-base">
		<template #mobile-header>
			<mobile-header :title="$t('client.offers')">
				<template #header-left>
					<mobile-back-button></mobile-back-button>
				</template>
			</mobile-header>
		</template>

		<template v-if="isLoading">
			<div class="relative h-screen">
				<div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
					<span class="loader"></span>
				</div>
			</div>
		</template>

		<main v-else class="px-4">
			<ListOffers />
		</main>

		<template #mobile-footer>
			<hasRole role_key="customer-offers:store">
				<div class="fixed bottom-0 bg-white p-4 w-full">
					<div class="flex items-center gap-3">
						<NuxtLink :to="localePath({ name: 'account.offers.create' })" class="bg-indigo-900 text-white rounded-lg p-3 capitalize  w-full text-center">
							<i class="fa-solid fa-plus me-3"></i>
							{{ $t('client.add_offer') }}
						</NuxtLink>
					</div>
				</div>
			</hasRole>
		</template>
	</NuxtLayout>
</template>